import React from 'react'
import { Typography } from '@mui/material'
import MainLayout from '../components/MainLayout'

export default function Error() {
  return (
    <MainLayout {...{ backLabel: 'Main', path: '/' }}>
      <Typography variant="h2" sx={{ wordBreak: 'normal', fontWeight: 'bold' }}>
        Error page not found
      </Typography>
    </MainLayout>
  )
}
