import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { useState } from 'react'
import Home from './pages/Home'
import Error from './pages/Error'
import Login from './pages/Login'
import Journal from './pages/Journal'
import RoadMap from './pages/Roadmap'
import Accomplishments from './pages/Accomplishments'
import ProtectedRoute from './pages/ProtectedRoute'
import SignUp from './pages/Signup'
import DailyAffirmations from './pages/DailyAffirmations'
import './App.css'
import Settings from './pages/Settings'
import AddJournal from './pages/AddJournal'

function App() {
  const [user, setUser] = useState(null)

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="login" element={<Login setUser={setUser}></Login>} />
          <Route path="signup" element={<SignUp setUser={setUser}></SignUp>} />
          <Route
            path="settings"
            element={
              <ProtectedRoute user={user}>
                <Settings user={user} />
              </ProtectedRoute>
            }
          />
          <Route
            path="dailyaffirmations"
            element={
              <ProtectedRoute user={user}>
                <DailyAffirmations user={user} />
              </ProtectedRoute>
            }
          />
          <Route
            path="journal"
            element={
              <ProtectedRoute user={user}>
                <Journal user={user} />
              </ProtectedRoute>
            }
          />
          <Route
            path="addjournal"
            element={
              <ProtectedRoute user={user}>
                <AddJournal user={user} />
              </ProtectedRoute>
            }
          />
          <Route
            path="accomplishments"
            element={
              <ProtectedRoute user={user}>
                <Accomplishments user={user} />
              </ProtectedRoute>
            }
          />
          <Route
            path="roadmap"
            element={
              <ProtectedRoute user={user}>
                <RoadMap user={user} />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<Error />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
