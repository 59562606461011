import React from 'react'
import { Button, Grid, Stack, Typography } from '@mui/material'
import Image from 'mui-image'
import carImage from '../sports-car.svg'
import { useNavigate } from 'react-router'

export default function Home() {
  const navigate = useNavigate()

  return (
    <Grid spacing={2} container direciton="column" alignItems="center" justifyContent="center" height="100%">
      <Grid item>
        <Stack justifyContent="center" alignItems="center" spacing={2}>
          <Image src={carImage} alt="car" />
          <Typography variant="h2" sx={{ wordBreak: 'normal', fontWeight: 'bold' }}>
            The Drive Formula
          </Typography>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
            Personalized career road map
          </Typography>
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              navigate('signup')
            }}
          >
            Get Started
          </Button>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => {
              navigate('login')
            }}
          >
            Already have an account? Sign in
          </Button>
        </Stack>
      </Grid>
    </Grid>
  )
}
