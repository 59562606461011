import React from 'react'
import { Button, TextField } from '@mui/material'
import { useNavigate } from 'react-router'
import MainLayout from '../components/MainLayout'

const Login = ({ setUser }) => {
  // const [name, setName] = useState('');
  // const [email, setEmail] = useState('');

  const navigate = useNavigate()

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (!name || !email) return;
  //   setUser({ name: name, email: email });
  //   navigate('/dashboard');
  // };

  return (
    <MainLayout {...{ backLabel: 'Login', path: '/' }}>
      <TextField fullWidth id="username" label="Username" />
      <TextField fullWidth id="password" label="Password" />
      <Button
        variant="outlined"
        fullWidth
        onClick={() => {
          setUser({ username: 'wwwpol', password: 'yoyomama' })
          navigate('/dailyaffirmations')
        }}
      >
        Login
      </Button>
    </MainLayout>
  )
}
export default Login
