import React from 'react'
import MainLayout from '../components/MainLayout'
import data from '../data'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Avatar from '@mui/material/Avatar'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { red } from '@mui/material/colors'
import FavoriteIcon from '@mui/icons-material/Favorite'
import ShareIcon from '@mui/icons-material/Share'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import FiberManualRecordSharpIcon from '@mui/icons-material/FiberManualRecordSharp'
import AddIcon from '@mui/icons-material/Add'
import { Fab } from '@mui/material'
import { useNavigate } from 'react-router'

export default function Journal({ user }) {
  const navigate = useNavigate()

  const addJournal = () => {
    navigate('/addjournal')
  }

  const journal = JSON.parse(localStorage.getItem('journal')) || data.journal

  console.log('Journal: ', user, data)
  return (
    <MainLayout user={user}>
      <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
        Journal
      </Typography>
      {journal.map((j) => (
        <Card key={j.id}>
          <CardHeader
            avatar={<FiberManualRecordSharpIcon sx={{ color: j.progress }} />}
            action={
              <IconButton aria-label="settings">
                <MoreVertIcon />
              </IconButton>
            }
            title={j.title}
            subheader={j.date}
          />
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              {j.text}
            </Typography>
          </CardContent>
        </Card>
      ))}
      <Fab color="secondary" aria-label="add" sx={{ position: 'absolute', right: 16, bottom: 64 }} onClick={addJournal}>
        <AddIcon />
      </Fab>
    </MainLayout>
  )
}
