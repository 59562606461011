const data = {
  journal: [
    {
      id: 0,
      title: 'Bad day',
      progress: 'red',
      date: '12/26/2022 7:12 pm',
      text: 'Oh man this was a bad day.  I did not stick to my goals and I fell apart.  I got mad at another employee. I almost got fired.',
    },
    {
      id: 1,
      title: 'Amazing day',
      progress: 'green',
      date: '12/27/2022 6:45 pm',
      text: 'I stuck with my goals and it paid off.  I feel so good.',
    },
    {
      id: 2,
      title: 'Whats up?',
      progress: 'yellow',
      date: '12/28/2022 6:31 pm',
      text: "I was just off today.  Things went ok, but I just couldn't do what I know I should do.  It ended up ok",
    },
  ],
  accomplishments: [
    {
      id: 0,
      date: '12/28/2022 6:31 pm',
      text: 'Today I accomplished my goal of reaching out to a coworker to get something done.  I feel a lot better.',
    },
    {
      id: 1,
      date: '12/29/2022 5:31 pm',
      text: "I communicated my vision of a project to upper management.  They liked it.  I don't know if they will do the project, but I reached my goal",
    },
  ],
  roadmap: [
    {
      id: 0,
      goal: '01/10/2023',
      text: 'Discuss my vision with the team',
    },
    {
      id: 1,
      goal: '01/21/2023',
      text: 'Create a prototype of the product that I want the org to do',
    },
    {
      id: 2,
      goal: '01/31/2023',
      text: 'Get approval from management to do the project',
    },
  ],
  affirmations: [
    {
      id: 0,
      text: 'You are the man.  You can do this.',
    },
    {
      id: 1,
      text: 'You can be everything you want to be if you just believe it.',
    },
    {
      id: 2,
      text: 'All our dreams can come true, if we have the courage to pursue them. --Walt Disney',
    },
  ],
  settings: [
    {
      id: 0,
      text: 'Daily Affirmation',
      time: '6:00 AM',
    },
    {
      id: 1,
      text: 'Daily Journal Reminder',
      time: '9:00 PM',
    },
  ],
}

export default data
