import React from 'react'
import MainLayout from '../components/MainLayout'
import data from '../data'
import { Card, CardHeader, CardContent, Typography } from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'

export default function Settings({ user }) {
  return (
    <MainLayout user={user}>
      <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
        Settings
      </Typography>
      {data.settings.map((j) => (
        <Card key={j.id}>
          <CardHeader avatar={<SettingsIcon />} title={j.text} />
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              {j.time}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </MainLayout>
  )
}
