import React, { useState } from 'react'
import { Grid, Stack, Button, BottomNavigation, BottomNavigationAction } from '@mui/material'
import { useNavigate } from 'react-router'
// import MenuIcon from '@mui/icons-material/Menu'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement'
import RouteIcon from '@mui/icons-material/Route'
import SettingsIcon from '@mui/icons-material/Settings'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'

export default function MainLayout({ backLabel, title, path, user, children }) {
  const navigate = useNavigate()
  const [value, setValue] = useState(0)

  return (
    <Grid spacing={2} container direciton="column" alignItems="center" justifyContent="center" height="100%">
      <Grid item>
        <Stack justifyContent="start" alignItems="start" spacing={2} sx={{ position: 'relative' }}>
          {/* <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
              <Toolbar>
                <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  {title}
                </Typography>
                <Button
                  color="inherit"
                  onClick={() => {
                    navigate('/login')
                  }}
                >
                  Login
                </Button>
              </Toolbar>
            </AppBar>
          </Box> */}
          {backLabel && path && (
            <Button
              color="secondary"
              startIcon={<KeyboardBackspaceIcon />}
              onClick={() => {
                navigate(path)
              }}
            >
              {backLabel}
            </Button>
          )}
          {children}
          {user && (
            <BottomNavigation
              showLabels
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue)
                navigate('/' + newValue)
              }}
              style={{ width: '100%' }}
            >
              <BottomNavigationAction value="settings" icon={<SettingsIcon />} />
              <BottomNavigationAction value="dailyaffirmations" icon={<SelfImprovementIcon />} />
              <BottomNavigationAction value="roadmap" icon={<RouteIcon />} />
              <BottomNavigationAction value="accomplishments" icon={<PlaylistAddCheckIcon />} />
              <BottomNavigationAction value="journal" icon={<MenuBookIcon />} />
            </BottomNavigation>
          )}
        </Stack>
      </Grid>
    </Grid>
  )
}
