import React from 'react'
import { Typography } from '@mui/material'
import MainLayout from '../components/MainLayout'
import TimeToLeaveRoundedIcon from '@mui/icons-material/TimeToLeaveRounded'
import data from '../data'
import { Card, CardHeader, CardContent, Fab } from '@mui/material'
import AddRoadRoundedIcon from '@mui/icons-material/AddRoadRounded'

export default function RoadMap({ user }) {
  return (
    <MainLayout user={user}>
      <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
        Road Map
      </Typography>
      {data.roadmap.map((j) => (
        <Card key={j.id}>
          <CardHeader avatar={<TimeToLeaveRoundedIcon color="success" />} title={j.title} subheader={j.date} />
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              {j.text}
            </Typography>
          </CardContent>
        </Card>
      ))}
      <Fab color="secondary" aria-label="add" sx={{ position: 'absolute', right: 16, bottom: 64 }}>
        <AddRoadRoundedIcon />
      </Fab>
    </MainLayout>
  )
}
