import React from 'react'
import { Button, TextField } from '@mui/material'
import { useNavigate } from 'react-router'
import MainLayout from '../components/MainLayout'

export default function Signup({ setUser }) {
  const navigate = useNavigate()

  return (
    <MainLayout {...{ backLabel: 'Login', path: '/' }}>
      <TextField fullWidth id="phone" label="Phone Number" />
      <TextField fullWidth id="username" label="Username" />
      <TextField fullWidth id="password" label="Password" />
      <Button
        variant="outlined"
        fullWidth
        onClick={() => {
          setUser({ username: 'wwwpol', password: 'yoyomama' })
          navigate('/dailyaffirmations')
        }}
      >
        Login
      </Button>
    </MainLayout>
  )
}
