import React from 'react'
import { Typography } from '@mui/material'
import MainLayout from '../components/MainLayout'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'

import data from '../data'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Avatar from '@mui/material/Avatar'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import { red } from '@mui/material/colors'
import FavoriteIcon from '@mui/icons-material/Favorite'
import ShareIcon from '@mui/icons-material/Share'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import AddIcon from '@mui/icons-material/Add'
import { Fab } from '@mui/material'
import { useNavigate } from 'react-router'

export default function Accomplishments({ user }) {
  return (
    <MainLayout user={user}>
      <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
        Accomplishments
      </Typography>
      {data.accomplishments.map((j) => (
        <Card key={j.id}>
          <CardHeader avatar={<CheckCircleRoundedIcon color="success" />} title={j.title} subheader={j.date} />
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              {j.text}
            </Typography>
          </CardContent>
        </Card>
      ))}
      <Fab color="secondary" aria-label="add" sx={{ position: 'absolute', right: 16, bottom: 64 }}>
        <AddIcon />
      </Fab>
    </MainLayout>
  )
}
