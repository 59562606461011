import React from 'react'
import {
  Button,
  DialogContentText,
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import { useNavigate } from 'react-router'
import MainLayout from '../components/MainLayout'
import FiberManualRecordSharpIcon from '@mui/icons-material/FiberManualRecordSharp'
import RadioGroup from '@mui/material/RadioGroup'
import InfoIcon from '@mui/icons-material/Info'
import { pink, yellow } from '@mui/material/colors'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useForm } from 'react-hook-form'

const { palette } = createTheme()
const theme = createTheme({
  palette: {
    pinky: palette.augmentColor({ color: pink }),
    summer: palette.augmentColor({ color: yellow }),
  },
})

export default function AddJournal({ user }) {
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()

  const [value, setValue] = React.useState('yellow')
  const [open, setOpen] = React.useState(false)
  const [helpText, setHelpText] = React.useState('')
  const [scroll, setScroll] = React.useState('paper')

  // const props = {
  //   checked: value,
  //   onClick: () => setValue((v) => !v),
  // }
  // const [alignment, setAlignment] = React.useState('green')

  const handleChange = (event, nextView) => {
    setValue(nextView)
  }

  const handleClose = () => {
    setOpen(false)
  }

  function onSubmit(data) {
    console.log({ data, value })
    try {
      const raw = localStorage.getItem('journal')
      const journal = typeof raw === 'string' ? JSON.parse(raw) : []
      const entry = { ...data, progress: value }
      localStorage.setItem('journal', JSON.stringify(journal.concat(entry)))
      navigate('/journal')
    } catch (err) {
      setOpen(true)
      console.log('Error: ', err)
      setHelpText('Unable to save journal entry.  Try again later')
    }
  }

  return (
    <>
      <MainLayout {...{ user }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
          Journal Entry
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormLabel id="demo-radio-buttons-group-label">Progress</FormLabel>

          <Stack direction="row">
            <ToggleButtonGroup
              color="primary"
              value={value}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
              orientation="vertical"
            >
              <ToggleButton value="red" style={{ justifyContent: 'flex-start' }}>
                <FiberManualRecordSharpIcon sx={{ color: 'red' }} /> No progress
              </ToggleButton>
              <ToggleButton value="yellow" style={{ justifyContent: 'flex-start' }}>
                <FiberManualRecordSharpIcon sx={{ color: 'yellow' }} />
                Limited progress
              </ToggleButton>
              <ToggleButton value="green" style={{ justifyContent: 'flex-start' }}>
                <FiberManualRecordSharpIcon sx={{ color: 'green' }} />
                Good progress
              </ToggleButton>
            </ToggleButtonGroup>

            <Stack>
              <IconButton
                sx={{ margin: '5px' }}
                onClick={() => {
                  setOpen(true)
                  setHelpText(
                    'Red. You are stopped on your progression. You had an event that stopped you on your path. These are lessons that can be learned from. You need to document this situation so you can use hindsight at the end of the week to see what you can learn from the situation and be able to use it if you have something similar come up in the future. There is always a solution to a problem. It may take us back on our road map but we can figure out the solution and continue to move in a forward positive direction when we figure out the solution. You sometimes need to let the problem go and focus on something else to come up with the solution. If you dwell on it and how negative it is then you will be digging a deeper hole to get out of. Remember there is always a solution!'
                  )
                }}
              >
                <InfoIcon />
              </IconButton>
              <IconButton
                sx={{ margin: '5px' }}
                onClick={() => {
                  setOpen(true)
                  setHelpText(
                    'Yellow. Taking the time to reflect on the best direction to go. Use this time to make plans to move forward. Look at your Professional Identity (Personal Resume) and see what is the best route to move forward. We can use the stop and smell the roses analogy. What do roses have to offer us? '
                  )
                }}
              >
                <InfoIcon />
              </IconButton>
              <IconButton
                sx={{ margin: '5px' }}
                onClick={() => {
                  setOpen(true)
                  setHelpText(
                    'Green. What action did you make to move forward in a difficult situation or to just move in the direction of your roadmap? Write about your accomplishments that made a positive forward direction on developing your Strengths or finishing a certification or education to be added to your Professional Identity. These can be looked at as you analyze your week.'
                  )
                }}
              >
                <InfoIcon />
              </IconButton>
            </Stack>
          </Stack>

          <TextField {...register('title')} fullWidth id="title" label="Title" />
          <TextField {...register('text')} fullWidth id="text" label="Text" multiline rows={4} />
          <Button variant="contained" fullWidth type="submit">
            Add
          </Button>
        </form>
      </MainLayout>

      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Progress</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            {helpText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
